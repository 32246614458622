<template>
  <div>
    <!-- 零售销售人员排行 -->
    <filter-view :loading="loading" @submit="loadEchart">
      <retail-Filter :show-opt="showOpt" :query="query" @init="loadEchart" />
    </filter-view>
    <div class="result">
      <div class="padding-10 fs-small fc-g">分析结果</div>
      <div class="round-panel bc-w">
        <div v-if="store && store.length">
          <div class="c h sb">
            <div class="no-flex padding-10"></div>
            <el-radio-group v-model="chartTypes" size="mini" @change="draw">
              <el-radio label="zzt">柱状图</el-radio>
              <el-radio label="tzt">饼图</el-radio>
            </el-radio-group>
          </div>
          <div class="c h sb sep-t">
            <div class="no-flex padding-10">显示图表前</div>
            <el-input-number v-model="showRankNum" size="mini" :min="1" :max="1000" :step="1" :precision="0" @change="draw" />
          </div>
          <div class="sep-t" ref="chart" style="height:420px"></div>
        </div>
        <div class="fc-g ta-c padding-10" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
      </div>
      <template v-if="store && store.length > 0">
        <div class="padding-10 fs-small fc-g">数据列表</div>
        <div class="round-panel bc-w">
          <div v-for="o in pagedStore" :key="o.rank" class="padding-05 sep-b">
            <div class="fs-large bold padding-05">排名{{ o.rank }}，{{ o.shopName || "-" }}，&nbsp;销售员（{{ o.name || "-" }}）</div>

            <template v-if="isReported">
              <div class="h c wrap fs-small lh-150">
                <div class="padding-05" style="width: 50%">
                  <div>销售金额：{{ $price(o.realCheckSumMoney) }}</div>
                </div>
                <div class="padding-05" style="width: 50%">
                  <div>销售数量：{{ o.checkSaleNumber || "-" }}</div>
                </div>

                <div class="padding-05" style="width: 50%">
                  <div>发货金额：{{ $price(o.checkSendMoney) }}</div>
                </div>
                <div class="padding-05" style="width: 50%">
                  <div>发货数量：{{ o.checkSendNumber || "-" }}</div>
                </div>
                <div class="padding-05" style="width: 50%">
                  <div>订单数量：{{ o.formCheckCount || "-" }}</div>
                </div>
                <div class="padding-05" style="width: 50%">
                  <div>均单值：{{ o.checkFormPerMoney || "-" }}</div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="h c wrap fs-small lh-150">
                <div class="padding-05" style="width: 50%">
                  <div>销售金额：{{ $price(o.realSumMoney) }}</div>
                </div>
                <div class="padding-05" style="width: 50%">
                  <div>销售数量：{{ o.saleNumber || "-" }}</div>
                </div>

                <div class="padding-05" style="width: 50%">
                  <div>发货金额：{{ $price(o.sendMoney) }}</div>
                </div>
                <div class="padding-05" style="width: 50%">
                  <div>发货数量：{{ o.sendNumber || "-" }}</div>
                </div>

                <div class="padding-05" style="width: 50%">
                  <div>订单数量：{{ o.formCount || "-" }}</div>
                </div>
                <div class="padding-05" style="width: 50%">
                  <div>均单值：{{ o.formPerMoney || "-" }}</div>
                </div>
              </div>
            </template>
          </div>
          <div class="ta-c">
            <el-pagination class="h5-pagination" :total="totalElements" :current-page.sync="page" :page-size="size" layout="prev, pager, next" hide-on-single-page />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import filterView from "../assembly/filter";
import retailFilter from "../assembly/filter/retail";
import request from "@/utils/request";
export default {
  components: {
    filterView,
    retailFilter,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      page: 1,
      size: 6,
      totalElements: null,
      store: [],
      showRankNum: 5,
      isReported: false,
      chartTypes: "zzt",
      chart: null,
      showOpt: {
        showGoodsType: true, //商品分类
        showDimension: false, //分析维度隐藏
        showReported: true, //仅显示已上报
        showRankGist: true, //显示排名依据
        showRangGoods: false, //商品范围
        showCommodity: false, //指定商品
        showLevel: false, //汇总级别
        showRank: false, //显示排名对象
        showYearonyear: false, //同比显示
        showMonth: false, //环比显示
        showCompareyear: false, //同比年度
        showCategory: false, //对比类型
        showTimeRange: true, //时间范围
        showDetail: false, //显示零售明细查询
      },
      query: {
        addressType: "area",
        dateGroup: "day",
        area: {
          treeNodeId: "1",
          treeNodeType: "group",
        },
        standard: {
          treeNodeId: "",
          StandardAreaGroup: "country", //标准行政区域
        },
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        reportOnly: false, //只统计已上报
        saleValueGroup: "realSumMoney", //排名依据默认销售金额
        page: 0,
        size: 10000,
      },
    };
  },
  watch: {
    store() {
      this.page = 1;
    },
  },
  computed: {
    pagedStore() {
      let start = (this.page - 1) * this.size,
        len = this.size;
      if (start + len > this.store.length) {
        len = this.store.length - start;
      }
      return this.store.slice(start, start + len);
    },
  },
  methods: {
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      if (!data.reportOnly) delete data.reportOnly;
      data.begDate = new Date(data.begDate);
      data.endDate = new Date(data.endDate);
      data.begDate = new Date(
        data.begDate.getFullYear(),
        data.begDate.getMonth(),
        data.begDate.getDate()
      ).getTime();
      data.endDate = new Date(
        data.endDate.getFullYear(),
        data.endDate.getMonth(),
        data.endDate.getDate() + 1
      ).getTime();
      
      
      if (data.addressType == "standard") {
        Object.assign(data, data.standard);
      } else {
        Object.assign(data, data.area);
      }
      delete data.standard;
      delete data.area;

      if (data.goods && data.goods.length)
        data.skuIds = data.goods.map((o) => o.id);
      delete data.goods;
      return data;
    },
    loadEchart() {
      if (this.query.reportOnly == false) {
        this.isReported = false;
      } else {
        this.isReported = true;
      }
      let data = Object.assign({}, this.makeParams());
      this.loading = true;
      request({
        url: "@host:analysis;api/analy/retail/sale/emp/rank",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.store = res.content;
          this.totalElements = res.totalElements;
          this.$nextTick((_) => {
            this.draw();
          });
        })
        .catch((err) => {
          this.store = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    draw(val) {
      if (!val) {
        this.showRankNum = 5;
      }
      if (this.store && this.store.length) {
        let sourceName = [];
        let sourceData1 = [];
        let binChart = [];
        let svgp = [];
        let legendsvgp = [];
        let yAxisName = "";
        (this.store || []).forEach((o) => {
          sourceName.push(o.name);
          if (
            this.query.saleValueGroup == "saleNumber" ||
            this.query.saleValueGroup == "checkSaleNumber" ||
            this.query.saleValueGroup == "sendNumber" ||
            this.query.saleValueGroup == "checkSendNumber"
          ) {
            sourceData1.push(o[this.query.saleValueGroup]);
            yAxisName = "数量";
          } else {
            sourceData1.push(
              Math.round(o[this.query.saleValueGroup] * 0.000001 * 100) / 100
            );
            yAxisName = "金额（万元）";
          }
        });
        switch (this.query.saleValueGroup) {
          case "realSumMoney":
            legendsvgp.push("销售金额");
            break;
          case "realCheckSumMoney":
            legendsvgp.push("已上报销售金额");
            break;
          case "saleNumber":
            legendsvgp.push("单品数量");
            break;
          case "checkSaleNumber":
            legendsvgp.push("已上报单品数量");
            break;
          case "sendMoney":
            legendsvgp.push("发货金额");
            break;
          case "checkSendMoney":
            legendsvgp.push("已上报发货金额");
            break;
          case "sendNumber":
            legendsvgp.push("发货数量");
            break;
          case "checkSendNumber":
            legendsvgp.push("已上报发货数量");
            break;
        }
        switch (this.query.saleValueGroup) {
          case "realSumMoney":
            svgp.push("销售金额");
            break;
          case "realCheckSumMoney":
            svgp.push("已上报销售金额");
            break;
          case "saleNumber":
            svgp.push("单品数量");
            break;
          case "checkSaleNumber":
            svgp.push("已上报单品数量");
            break;
          case "sendMoney":
            svgp.push("发货金额");
            break;
          case "checkSendMoney":
            svgp.push("已上报发货金额");
            break;
          case "sendNumber":
            svgp.push("发货数量");
            break;
          case "checkSendNumber":
            svgp.push("已上报发货数量");
            break;
        }
        if (!this.chart) {
          this.chart = echarts.init(this.$refs.chart, "light");
        }
        this.chart.clear();
        binChart = (this.store || []).map((o) => {
          if (
            this.query.saleValueGroup == "saleNumber" ||
            this.query.saleValueGroup == "checkSaleNumber" ||
            this.query.saleValueGroup == "sendNumber" ||
            this.query.saleValueGroup == "checkSendNumber"
          ) {
            return {
              name: o.name,
              value: o[this.query.saleValueGroup],
            };
          } else {
            return {
              name: o.name,
              value:
                Math.round(o[this.query.saleValueGroup] * 0.01 * 100) / 100,
            };
          }
        });
        binChart = binChart.slice(0, this.showRankNum);
        if (this.chartTypes == "zzt") {
          this.chart.setOption({
            tooltip: {
              trigger: "axis",
              confine: true,
            },
            calculable: true,
            grid: {
              left: 50,
              right: 20,
              bottom: 50,
              top: 50,
            },
            xAxis: [
              {
                type: "category",
                data: sourceName.slice(0, this.showRankNum),
                axisLabel: {
                  formatter: function (value) {
                    if (value.length > 5) {
                      return value.substr(0, 5) + "…";
                    }
                    return value;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                name: yAxisName,
              },
            ],
            series: [
              {
                name: svgp,
                type: "bar",
                data: sourceData1.slice(0, this.showRankNum),
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      position: "top",
                    },
                  },
                },
              },
            ],
            dataZoom: {
              show: sourceName.slice(0, this.showRankNum).length > 5, // 为true 滚动条出现
              filterMode: "weakFilter",
              type: "slider", // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
              height: 15,
              start: 0,
              end: (5 / sourceName.slice(0, this.showRankNum).length) * 100,
              bottom: 10,
              handleIcon:
                "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
              handleSize: "80%",
              zoomLock: true,
              showDetail: false,
            },
          });
        } else if (this.chartTypes == "tzt") {
          if (!this.chart) {
            this.chart = echarts.init(this.$refs.chart, "light");
          }
          this.chart.clear();
          this.chart.setOption({
            title: {
              text: "销售人员排行分析饼图",
              left: "center",
              top: 20,
            },
            tooltip: {
              trigger: "item",
              confine: true,
              formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            series: [
              {
                name: svgp,
                type: "pie",
                radius: "55%",
                center: ["50%", "50%"],
                data: binChart.sort(function (a, b) {
                  return a.value - b.value;
                }),
                label: {
                  formatter: function (p) {
                    if (p.name.length > 5) return p.name.substr(0, 5) + "…";
                    else return p.name;
                  },
                },
              },
            ],
          });
        }
      } else {
        this.chart = null;
      }
    },
  },
};
</script>
